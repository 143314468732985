@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@font-face {
    font-family: 'MuseoSansRound';
    src: url('/src/assets/fonts/MuseoSansRounded100.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MuseoSansRound';
    src: url('/src/assets/fonts/MuseoSansRounded300.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MuseoSansRound';
    src: url('/src/assets/fonts/MuseoSansRounded500.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MuseoSansRound';
    src: url('/src/assets/fonts/MuseoSansRounded700.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MuseoSansRound';
    src: url('/src/assets/fonts/MuseoSansRounded900.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MuseoSansRound';
    src: url('/src/assets/fonts/MuseoSansRounded1000.otf') format('opentype');
    font-weight: 1000;
    font-style: normal;
  }
  
/* Custom CSS for the background transition */
#landing-page {
    background: linear-gradient(to bottom right, #d0b2ff, #f3b3da, #c5f3ff);
    transition: background 1s ease;
}
  
.bg-black {
    background: black;
}

/* Add keyframes for the pulsing animation */
@keyframes pulse {
    0%, 100% {
        transform: scale(1); /* Start and end at normal size */
    }
    50% {
        transform: scale(0.9); /* Shrink at the midpoint */
    }
}

/* Add keyframes for the rattle animation */
@keyframes rattle {
    0%, 100% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(-5deg); }
    75% { transform: rotate(5deg); }
}

/* Apply the animation to the logo */
.pulsing-logo {
    animation: pulse 3s infinite ease-in-out;
}

/* The button starts without animation */
.rattle-button {
    animation: none;
}

/* The rattle animation is applied only when the active class is present */
.rattle-button.active {
    animation: rattle 0.5s ease-in-out;
}

.container::-webkit-scrollbar {
  display: none;
}

.container {
  scrollbar-width: none;
}

.container {
  -ms-overflow-style: none;
}
